<template>
	<v-card class="rounded-t-lg card" flat>
		<div v-if="!hasNoCalendarPrices" class="circle-container d-flex justify-end">
			<p class="mr-1 mb-4"><span class="circle circle-budget"></span>{{ $t('calendar.lowPrice') }}</p>
		</div>
		<v-card-title class="d-flex justify-space-between align-center text-left pa-0">
			<v-btn small icon class="calendar-navigation" @click="$refs.calendar.prev()" :disabled="prevMonthDisabled">
				<v-icon>mdi-chevron-double-left</v-icon>
			</v-btn>
			<p class="text-capitalize text-h6 font-weight-medium pa-0 calendar-month">{{ $refs.calendar?.title }}</p>
			<v-btn small icon class="calendar-navigation" @click="$refs.calendar.next()" :disabled="nextMonthDisabled">
				<v-icon>mdi-chevron-double-right</v-icon>
			</v-btn>
		</v-card-title>
		<v-calendar
			v-show="!hasNoCalendarPrices"
			v-model="firstCalendarDay"
			:now="today"
			flat
			ref="calendar"
			type="month"
			:locale="$i18n.locale"
			:weekdays="weekdays"
			:weekday-format="weekdayFormat"
			@change="calendarChange"
			@click.prevent="dateClicked">
			<template v-slot:day-label="{day, past, date}">
				<div class="calendar-cell" @click="isCalendarAvailableDate(past, date) ? dateClicked(date) : undefined">
					<div :class="getDayClass(date)" class="day">
					<span class="day-number" :class="{'past-day' : past, 'unavailable' : loading || !calendarPrices[date]?.starting_from_price}">{{ day }}</span>
					</div>
						<div class="price-container">
						<p v-if="!past && loading && isDayFromSameMonth(date)" class="price loading">
							<!-- Used for a blurry effect -->
							{{ 13.00 }}
						</p>
						<p v-if="!loading && isCalendarAvailableDate(past, date)" class="price font-weight-bold" :class="{'lower_price': samePriceAllMonth || isLowerPrice(calendarPrices[date].starting_from_price), 'higher_price': false}">
							{{ $options.filters.formatPriceCalendar(calendarPrices[date].starting_from_price, $i18n.locale) }}
						</p>
					</div>
				</div>
			</template>
		</v-calendar>
		<div v-show="hasNoCalendarPrices"
			class="no-price"
			data-testid="no-calendar-price-image">
			<div><img src="@/assets/images/no-calendar-price-message.png" :alt="$t('calendar.no-price.message')"/></div>
			<div class="no-price_message"> {{ $t('calendar.no-price.message') }} </div>
		</div>
	</v-card>
</template>
<script>
import moment from 'moment';
import { EComService } from '@connectngo/sdk'
import WindowWidthMixin from '@/mixins/WindowWidthMixin'
import momentTZ from 'moment-timezone'

export default {
	name: "ProductsDatePicker",
	mixins: [WindowWidthMixin],
	props : {
		productsSelected : {
			type: Map,
			required: true,
		},
		limitMonthsDisplayed : {
			type : Boolean,
			default : false,
		},
		maxMonthsDisplayed : {
			type : Number,
			default : 12,
		}
	},
	data() {
		return {
			firstLoad: true,
			loading : true,
			daySelected : null,
			firstCalendarDay : moment().format('YYYY-MM-DD'),
			calendarPrices : [],
			calendarStartUnix : null,
			calendarEndUnix : null,
			calendarPriceAverage: 0,
			samePriceAllMonth: false,
			today: new Date().toISOString().split('T')[0], // today's date in YYYY-MM-DD format
		}
	},
	computed : {
		weekdays() {
			if(moment().localeData().firstDayOfWeek() === 0) {
				return  [0, 1, 2, 3, 4, 5, 6]
			} else {
				return [1, 2, 3, 4, 5, 6, 0]
			}
		},
		prevMonthDisabled() {
			return this.firstCalendarDay.substring(0, this.firstCalendarDay.length-3)  === moment().format('YYYY-MM');
		},
		nextMonthDisabled() {
			if (!this.maxMonthsDisplayed) {
				return false;
			}
			const maxMonth = moment().add(this.maxMonthsDisplayed, 'months');
			return this.firstCalendarDay.substring(0, this.firstCalendarDay.length-3)  === maxMonth.format('YYYY-MM');
		},
		hasNoCalendarPrices() {
			return Array.isArray(this.calendarPrices) && !this.calendarPrices.length && !this.loading;
		},
		unavailableBeforeUnix() {
			let unavailableBefore = null;
			this.productsSelected.forEach(({product}) => {
				if (product.data.unavailable_before &&
					(!unavailableBefore || moment.unix(product.data.unavailable_before).isAfter(moment.unix(unavailableBefore)))) {
					unavailableBefore = product.data.unavailable_before
				}
			})

			return unavailableBefore;
		},
	},
	methods : {
		calendarChange(e) {
			this.loading = true;
			const startToday = moment(e.start.date).isSame(new Date(), "month");
			const startAfterUnavailableBefore = !this.unavailableBeforeUnix ||
				moment(e.start.date).isAfter(moment.unix(this.unavailableBeforeUnix), "minute");

			if (startToday && startAfterUnavailableBefore) {
				this.calendarStartUnix = this.$options.filters.dateToTenantTimezone().unix()
			} else if(startAfterUnavailableBefore) {
				this.calendarStartUnix = this.$options.filters.dateToTenantTimezone(
					moment(e.start.date).startOf('day')
				).unix();
			} else {
				this.calendarStartUnix = this.unavailableBeforeUnix
			}

			this.calendarEndUnix = moment(e.end.date).endOf('day').unix();

			this.getProductPrices(this.calendarStartUnix, this.calendarEndUnix)
		},
		async getProductPrices(startUnix, endUnix) {
			this.loading = true;

			const productsDataFormat = this.productsDataFormat()

			if (this.firstLoad) {
				await new EComService().getNextAvailableDate(productsDataFormat)
					.then( res => {
						if (Object.keys(res.data).length > 0) {
							const date = Object.keys(res.data)[0];
							const today = moment().format('YYYY-MM-DD');

							this.calendarStartUnix = this.$options.filters.dateToTenantTimezone(
								moment(date === today ? undefined : date).startOf('day')
							).unix();

							this.firstCalendarDay = date;

							this.calendarEndUnix = moment(date).endOf('month').unix();

							startUnix = this.calendarStartUnix;
							endUnix = this.calendarEndUnix;

							this.firstLoad = false;
						}
					})
					.catch(err => this.$handleError(this, err))
			}

			await new EComService().getProductsCalendarPrices(startUnix, endUnix, productsDataFormat)
				.then( res => {
					this.calendarPrices = res.data;
				})
				.catch(err => this.$handleError(this, err))
				.finally(() => {
					const startInTenantTz = this.getDateFromUnixToTenantTimezone(startUnix)

					if(startInTenantTz.isSame(this.firstCalendarDay, 'month')) {
						this.loading = false
					}
				})
		},
		productsDataFormat() {
			const productsDataFormat = Array.from(this.productsSelected.entries()).flatMap(([, value]) => {

				const productEntries = [{ id: value.id, quantity: value.quantity }];

				if (value.modifiers.size > 0) {

					const modifiersEntries = [];

					value.modifiers.forEach(modifierValue => {

							modifiersEntries.push({
								id: modifierValue.modifier.id,
								quantity: modifierValue.quantity
							})
					});

					if (modifiersEntries.length > 0 && typeof modifiersEntries !== "undefined") {
						productEntries.push(...modifiersEntries);
					}
				}

				return productEntries;
			});

			return productsDataFormat;

		},
		getDateFromUnixToTenantTimezone(unix){
			const date = momentTZ(moment.unix(unix));
			const serverOffset = date.utcOffset();
			date.tz(this.$root.websiteConfig.data.tenant.timezone);
			const localOffset = date.utcOffset();
			const diffInMinutes = serverOffset - localOffset;
			return moment.unix(unix).add(diffInMinutes, "minutes");
		},
		dateClicked(date) {
			if (this.loading || !this.isDayFromSameMonth(date)) {
				return;
			}
			this.daySelected = date;
		},
		isDayFromSameMonth(day) {
			return moment(day).isSame(this.firstCalendarDay, 'month')
		},
		weekdayFormat(date) {
			return moment(date.date).format('dd');
		},
		isCalendarAvailableDate(past, date) {
			return !past && this.calendarPrices[date]?.starting_from_price >= 0 && this.isDayFromSameMonth(date)
		},
		getAveragePrice() {
			const { sum, count } = Object.values(this.calendarPrices).reduce(
				(acc, { starting_from_price }) => {
					acc.sum += starting_from_price;
					acc.count += 1;
					return acc;
				},
				{ sum: 0, count: 0 }
			);

			return count > 0 ? Math.round(sum / count) : 0;
		},
		checkSamePrices() {
			const prices = Object.values(this.calendarPrices).map(item => item.starting_from_price);
			return prices.every(price => price === prices[0]);
		},
		isLowerPrice(price) {
			return price <= this.calendarPriceAverage;
		},
		getDayClass(date) {
			return {
				'current-day': date === this.today && this.selectedDayClass !== 'day-selected',
				'day-selected': this.daySelected === date,
			};
		},
	},
	watch : {
		daySelected(newVal) {
			// If the selected day is the same as today, remove 'current-day' and only keep 'day-selected'
			if (newVal === this.today) {
				this.selectedDayClass = 'day-selected'; // Only apply day-selected
			} else {
				this.selectedDayClass = newVal ? 'day-selected current-day' : 'current-day';
			}
			const datePrices = newVal ? this.calendarPrices[newVal] : null;
			this.$emit('dateSelected', newVal, datePrices)
		},
		firstCalendarDay() {
			this.daySelected = null;
		},
		calendarPrices() {
			this.calendarPriceAverage = this.getAveragePrice();
			this.samePriceAllMonth = this.checkSamePrices();
			this.$emit('calendarPricesStateChanged', this.calendarPrices.length === 0);
		},
		hasNoCalendarPrices(newValue) {
			this.$emit('calendarPricesStateChanged', newValue);
		}
	},
	mounted () {
		// Initially, make sure to set the class properly based on the current selection
		if (this.daySelected === this.today) {
			this.selectedDayClass = 'day-selected'; // Only day-selected if it's today
		} else {
			this.selectedDayClass = 'current-day'; // Default to current-day if any other day
		}
	}

}
</script>

<style scoped lang="scss">
	.calendar-cell {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 70px;
		width: 70px;
		padding: 10px;

		.day-number {
			font-size: 1rem;
		}
		.price-container {
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.price {
			font-weight: 600;
			font-size: 0.75rem;
			margin: 0;
		}
		.price.loading {
			color: transparent;
			animation: blur 3s ease-out infinite;
			-webkit-animation: blur 3s ease-out infinite;
		}
	}
	.day{
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.day-selected {
		background-color: $blackish;
		border-radius: 30%;
		color: #ffffff;
		width: 32px;
		height: 42px;
	}
	.current-day {
		background: $light-grey;
		color: black;
		border-radius: 50%;
	}
	.price.loading {
		color: transparent;
		animation: blur 3s ease-out infinite;
		-webkit-animation: blur 3s ease-out infinite;
	}
	::v-deep  {

		.v-calendar-weekly__head-weekday {
			text-transform: none !important;
		}
		.theme--light.v-calendar-weekly .v-calendar-weekly__day,
		.theme--light.v-calendar-weekly,
		.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
			border: none ;
			font-size: 0.9rem;
			color: $blackish;
		}

		.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
			background-color: #ffffff;
		}
		.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
			background-color: transparent;
		}

		.past-day, .unavailable {
			color:$grey-light !important;
		}

		.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday, .calendar-month {
			font-size: 0.9rem;
			font-weight: 600;
		}

		.calendar-month, .v-btn.calendar-navigation {
			color: $blackish;
		}
		.v-calendar-weekly__day {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.v-calendar-weekly__head-weekday {
			margin-top: 18px;
			margin-bottom: 18px;
		}
		@media (max-width: 600px) {
			.calendar-month, .v-btn.calendar-navigation {
				font-size: 1rem !important;
				margin-bottom: 0 !important;
			}
			.calendar-cell .day-number{
				font-size: 0.9rem !important;
			}
		}

	}
	@keyframes blur {
		0%		{text-shadow:  0 0 8px $grey-dark; opacity:0.75;}
		100%	{text-shadow:  0 0 6px $grey-dark; opacity:1;}
	}

	@-webkit-keyframes blur {
		0%		{text-shadow:  0 0 8px $grey-dark; opacity:0.75;}
		100%	{text-shadow:  0 0 6px $grey-dark; opacity:1;}
	}
	.no-price {
		height: 425px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&_message {
			font-size: 18px;
			font-weight: bold;
		}
	}
	.lower_price {
		color: #31C6C5;
	}
	.circle-container {
		font-size: 14px !important;
	}
	.circle {
		display: inline-block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		margin-right: 5px;
	}
	.circle-budget {
		background-color: #31C6C5;
	}
	p {
		margin: 0;
		display: flex;
		align-items: center;
	}

	@media (max-width: 600px)  {
		.calendar-cell {
			.price {
				font-size: 0.65rem;
			}
		}
	}


</style>
